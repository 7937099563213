@tailwind base;
@tailwind components;
@tailwind utilities;

fieldset {
	@apply relative;
}
.standard-input select,
.standard-input input[type='text'],
.standard-input input[type='date'],
.standard-input input[type='email'],
.standard-input input[type='password'],
textarea {
	@apply focus:outline-none border-2 text-black focus:border-slate-800 px-2 placeholder-transparent w-full mb-8 rounded;
}
.classic-input select,
.classic-input input[type='text'],
.classic-input input[type='date'],
.classic-input input[type='email'],
.classic-input input[type='password'],
textarea {
	@apply focus:outline-none border-2 text-black focus:border-slate-800 px-2 placeholder-black/50 w-full rounded;
}
select,
input[type='text'],
input[type='date'],
input[type='password'],
input[type='email'] {
	@apply h-10;
}
textarea {
	@apply py-2;
}
.standard-input-label:not(.text-label) {
	@apply absolute left-[5px] px-1.5 text-base peer-placeholder-shown:top-[6px] peer-placeholder-shown:text-lg text-slate-500 peer-placeholder-shown:text-black peer-placeholder-shown:opacity-50 transition-all ease-in-out bg-white;
	top: -0.8rem;
}
.without-border select,
.without-border input[type='text'],
.without-border input[type='date'],
.without-border input[type='email'],
.without-border input[type='password'],
.without-border textarea {
	@apply !border-0 !rounded-none;
}

.check {
	font-size: 16px;
	position: relative;
	width: 1.3em;
	height: 1.3em;
	z-index: -1;
	@apply text-slate-700;
}

.check::before {
	content: '';
	height: 0.2em;
	border-radius: 3px;
	position: absolute;
	/*   transition: all .5s ease-in-out; */
	/*   transition-property: transform, width, top, left; */
	transition: all 0.3s ease-in-out;
	transform: rotate(-45deg);
	width: 1.2em;
	top: 0.6em;
	left: 0em;
	@apply bg-red-800;
}
/* cubic-bezier(0.86, 0.98, 0.32, -0.01) */
.check::after {
	content: '';
	height: 0.2em;
	width: 1.2em;
	border-radius: 3px;
	position: absolute;
	top: 0.6em;
	transition: all 0.3s ease-in-out;
	left: 0em;
	transform: rotate(45deg);
	@apply bg-red-800;
}

input:checked + div .check::before {
	top: 0.85em;
	left: 0em;
	transform: rotate(45deg);
	width: 0.7em;
	transition: all 0.3s ease-in-out;
	@apply bg-green-800;
}

input:checked + div .check::after {
	transition: all 0.3s ease-in-out;
	left: 0.25em;
	transform: rotate(-55deg);
	@apply bg-green-800;
}

@layer components {
	.btn {
		@apply py-1.5 px-6 rounded-lg;
	}
	.btn-light {
		@apply border-2 border-slate-700 text-slate-700 focus:bg-slate-100 focus:border-slate-600 hover:bg-slate-100 hover:border-slate-600;
	}
	.btn-primary {
		@apply border-2 border-slate-700 bg-slate-700 text-white focus:border-slate-600 focus:bg-slate-600 hover:border-slate-600 hover:bg-slate-600;
	}
	.ac-table {
		@apply w-full mb-16;
	}
	.ac-table thead {
		@apply border bg-slate-700 text-white;
	}
	.ac-table thead tr th {
		@apply px-2 py-1 text-left;
	}
	.ac-table thead tr th span {
		@apply min-w-max inline-block;
	}
	.ac-table tbody tr {
		@apply text-left hover:bg-gray-100;
	}
	.ac-table tbody tr td {
		@apply border px-2 py-2;
	}
	input.hide-indicator::-webkit-calendar-picker-indicator {
		display: none !important;
	}
	.datalist-arrow:after {
		content: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2"><path stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7" /></svg>');
		margin-left: -20px;
		pointer-events: none;
		width: 18px;
		display: inline-block;
		transform: translateY(3px);
	}

	.lds-spinner {
		color: official;
		display: inline-block;
		position: relative;
		width: 40px;
		height: 25px;
		transform: scale(0.25);
	}
	.lds-spinner div {
		transform-origin: 40px 40px;
		animation: lds-spinner 1.2s linear infinite;
	}
	.lds-spinner div:after {
		content: ' ';
		display: block;
		position: absolute;
		top: 3px;
		left: 37px;
		width: 6px;
		height: 18px;
		border-radius: 20%;
		background: #888;
	}
	.lds-spinner div:nth-child(1) {
		transform: rotate(0deg);
		animation-delay: -1.1s;
	}
	.lds-spinner div:nth-child(2) {
		transform: rotate(30deg);
		animation-delay: -1s;
	}
	.lds-spinner div:nth-child(3) {
		transform: rotate(60deg);
		animation-delay: -0.9s;
	}
	.lds-spinner div:nth-child(4) {
		transform: rotate(90deg);
		animation-delay: -0.8s;
	}
	.lds-spinner div:nth-child(5) {
		transform: rotate(120deg);
		animation-delay: -0.7s;
	}
	.lds-spinner div:nth-child(6) {
		transform: rotate(150deg);
		animation-delay: -0.6s;
	}
	.lds-spinner div:nth-child(7) {
		transform: rotate(180deg);
		animation-delay: -0.5s;
	}
	.lds-spinner div:nth-child(8) {
		transform: rotate(210deg);
		animation-delay: -0.4s;
	}
	.lds-spinner div:nth-child(9) {
		transform: rotate(240deg);
		animation-delay: -0.3s;
	}
	.lds-spinner div:nth-child(10) {
		transform: rotate(270deg);
		animation-delay: -0.2s;
	}
	.lds-spinner div:nth-child(11) {
		transform: rotate(300deg);
		animation-delay: -0.1s;
	}
	.lds-spinner div:nth-child(12) {
		transform: rotate(330deg);
		animation-delay: 0s;
	}
	@keyframes lds-spinner {
		0% {
			opacity: 1;
		}
		100% {
			opacity: 0;
		}
	}
}
